var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "p",
        {
          staticClass: "m-0",
        },
        [_vm._v(_vm._s(_vm.data.vip.user.englishName))]
      ),
      _c(
        "span",
        {
          staticClass: "badge badge-pill badge-info",
        },
        [
          _vm.data.status === _vm.classroomConstants.CLASSROOM_STATUS.PROBLEM &&
          _vm.isDemo(_vm.data.classType)
            ? _c("span", [_vm._v(_vm._s(_vm.$t("problemDemoClass")))])
            : _vm.data.status ===
              _vm.classroomConstants.CLASSROOM_STATUS.DEMO_PAID
            ? _c("span", [_vm._v(_vm._s(_vm.$t("demoPaid")))])
            : _vm._e(),
        ]
      ),
      _vm.data.vip.user.vipStatus === _vm.classroomConstants.VIP_STATUS.SHOW &&
      !_vm.beforeAnalysisDeadline &&
      _vm.data.vip.consultantVipAnalysis.filledAt &&
      _vm.isJrClass
        ? [
            _c(
              "router-link",
              {
                staticClass: "btn",
                class: [
                  _vm.data.vip.consultantVipAnalysis.overdue
                    ? "btn-warning"
                    : "btn-success",
                ],
                attrs: {
                  to: {
                    name: "consultant-view-analysis",
                    query: {
                      caId: _vm.data.vip.consultantVipAnalysis.caId,
                      vipId: _vm.data.vip.vipId,
                    },
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  attrs: {
                    icon: "chart-bar",
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass: "filled-at-text",
              },
              [_vm._v(_vm._s(_vm.data.vip.consultantVipAnalysis.filledAt))]
            ),
          ]
        : _vm._e(),
      _c("div", [
        _c(
          "span",
          {
            staticClass: "badge badge-pill",
            class: _vm.getStudentStatus(_vm.data.vip.user.vipStatus).class,
          },
          [
            _vm._v(
              _vm._s(_vm.getStudentStatus(_vm.data.vip.user.vipStatus).text)
            ),
          ]
        ),
      ]),
      !_vm.isJrClass
        ? _c("div", [
            _c(
              "span",
              {
                staticClass: "text-danger",
              },
              [_vm._v(_vm._s(_vm.$t("wuwowPlatformFilledOutNotice")))]
            ),
            _c("img", {
              staticClass: "smile-face-img ml-1",
              attrs: {
                src: _vm.smileFaceImg,
              },
            }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }